import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Snatch 5-5-5-5\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`15-Power Snatch (95/65)`}</p>
    <p>{`15-T2B`}</p>
    <p>{`400M Run`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts August 26th.  Email Eric for more
details: Fallscitystrength\\@gmail.com`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Friendly Reminder.  As posted on our CrossFit the Ville Schedule
page since moving to our East Main location:`}</strong></p>
    <p><strong parentName="p">{`OPEN GYM HOURS`}</strong></p>
    <p><em parentName="p">{`(Open Gym when CrossFit classes aren’t in session)`}</em></p>
    <p><strong parentName="p">{`Monday — Friday`}</strong></p>
    <p>{`7am-12pm, 1pm-4:30pm, 6:30pm-8pm`}</p>
    <p><strong parentName="p">{`Saturday`}</strong></p>
    <p>{`10am-12pm`}</p>
    <p><strong parentName="p"><em parentName="strong">{` `}{`*`}{`*`}{`The only exception during class times are working in the open
gym area and on the weightlifting platforms.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      